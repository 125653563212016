.select-list {
    table-layout: fixed;
    width: 100%;
}

.select-list .selectable {
    cursor: pointer;
}

.select-list .selectable td:last-child {
    padding-right: 10px;
}

.select-list .selectable td:first-child {
    padding-left: 24px;
}

.select-list .selectable td {
    border: 1px solid #e9e9e9;
    border-width: 0 0 1px;
    max-width: 50%;
    overflow: hidden;
    padding: 15px 0 15px 3px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.select-list .selectable:active {
    background: #e9e9e9;
    border-color: #ccc;
}

.select-list .selectable:hover {
    background: #f4f4f4;
    border-color: #d9d9d9;
}
