/* Based on orginal works' .less file. */

.NPSInput {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    border-top: 1px solid #e5e5e5;
    background: #fff;
    box-shadow: 0px -10px 10px rgba(200, 200, 200, 0.08);
    z-index: 10000000;
  }
  .NPSInput.animated {
    animation-duration: 2s;
    animation-name: NPSInput-slidein;
  }
  .NPSInput .NPSInput-Close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    outline: none;
    display: inline-block;
    zoom: 1;
    line-height: normal;
    white-space: nowrap;
    vertical-align: baseline;
    text-align: center;
    cursor: pointer;
    user-select: none;
    font-family: inherit;
    font-size: 100%;
    padding: 0.5em 1em;
    text-decoration: none;
    border: 0;
    opacity: 0.4;
    font-size: 16px;
  }
  .NPSInput .NPSInput-Close:hover {
    opacity: 1;
  }
  .NPSInput .NPSInput-Inner {
    width: 100%;
    max-width: 1000px;
    margin: 20px auto;
    text-align: center;
  }
  .NPSInput .NPSScale {
    /* width: 418px; */
    margin: 0px auto;
  }
  .NPSInput .NPSScale .NPSScale-Values .NPSScale-Value {
    padding: 0px 3px;
    display: inline-block;
  }
  .NPSInput .NPSScale .NPSScale-Values .NPSScale-Value div {
    background: #f2f5fd;
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 32px;
    cursor: pointer;
    transition: 0.15s ease all;
    color: #999;
  }
  .NPSScale-Label {font-style: italic; margin: auto 12px;}
  .NPSScale-Value {font-weight: bold;}
  .NPSInput .NPSScale .NPSScale-Values .NPSScale-Value.selected div {
    background: #3884ff;
    color: #fff;
  }
  .NPSInput .NPSScale .NPSScale-Values .NPSScale-Value:hover div {
    transform: scale(1.25);
  }
  .NPSInput .NPSScale .NPSScale-Legend {
    display: flex;
    margin-top: 12px;
  }
  .NPSInput .NPSScale .NPSScale-Legend .NPSScale-Label {
    flex: 1;
    color: #999;
    font-size: 12px;
  }
  .NPSInput .NPSScale .NPSScale-Legend .NPSScale-Label.left {
    text-align: left;
  }
  .NPSInput .NPSScale .NPSScale-Legend .NPSScale-Label.right {
    text-align: right;
  }
  .NPSInput .NPSInput-Message {
    margin: 0px;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: bold;
  }
  @keyframes NPSInput-slidein {
    from {
      bottom: -100%;
    }
    to {
      bottom: 0px;
    }
  }
  body {
    /* font-size: 14px; */
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  