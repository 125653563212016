body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
div#statusDiv {font-size: 14px;}
#root {height: 100%;}
.topmargin {margin-top: .8em;}

div#sdk pre {border: none;}
#outputDiv {border-top: 1px solid #dddddd;}

.hidden {display: none;}

.aHover {
    text-decoration: none;
    color: black;
    font-weight: bold;
}
.aHover:hover {
    text-decoration: underline;
}


pre, code, .monofont {
  font-family: "Consolas", "Bitstream Vera Sans Mono", "Monaco", "Courier New", Courier, monospace !important;
}
.markdown code {background-color: #eff0f1;}

#jsoninput {margin-left: 2em; width: 100%;}


.overlaycentered {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}
.overlaycentered span {
   color: white;
   font-size: x-large; 
}

#status {
  height: 130px;
  overflow: auto;
  overflow-wrap: break-word;
}

#sdk pre code  {
  display: block;
  overflow: auto;
  overflow-wrap: break-word;
}

#textButton {margin-top: 2.5em;}
#textButton div {
  display: inline-block;
  margin-right: 2em;
  width: 20em;
}

#npsSurvey div {
    display: inline-block;
    margin-right: 2em;
    width: 50em;
}

/* .prism-code {font-size: 1.1em !important;} */
.prism-code .token.property {
  color: darkblue;
}