.Status {margin-top: 10px; margin-bottom: 0px;}
.Status.Danger_1 {
    font-size: 13px;
    margin-bottom: 16px;
    position: relative;
    color: rgb(178, 43, 49);
    border-width: 1px 1px 1px 4px;
    border-style: solid;
    border-image: initial;
    border-left: 4px solid rgb(209, 50, 57);
    border-radius: 2px;
    padding: 14px 36px 14px 40px;
    background: rgb(255, 238, 233);
    border-color: rgba(209, 50, 57, 0.27) rgba(209, 50, 57, 0.27) rgba(209, 50, 57, 0.27) rgb(209, 50, 57); 
}

.Status_regular {margin-top: 10px; margin-bottom: 0px;}
.Status_bold {font-weight: bold;}
.Status_error {color: rgb(178, 43, 49);}
p, .Status_2nd_line {margin-top: 0px; margin-bottom: 0px;}
